import React from 'react';
import styles from './styles.module.css';
import Loader from '../../uiComponents/Loader/Loader';

function OttV3Loader() {
  return (
    <div className={styles.ott_v3_container}>
      <Loader color="#3E3CFF" />
    </div>
  );
}

export default OttV3Loader;
