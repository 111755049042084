import React, { useState, useEffect } from 'react';
import Contexts from './Contexts';

const AppStorage = ({ children }) => {
  // should store the json from /public/FormConfig.json
  const [formConfig, setFormConfig] = useState({});

  const [userDoc, setUserDoc] = useState({});

  const [loggedIn, setLoggedIn] = useState(false);

  const [isDarkMode, setIsDarkMode] = useState(true);

  const [loadingSpinnerEnabled, setLoadingSpinnerEnabled] = useState(false);

  const [messageDialogState, setMessageDialogState] = useState({
    title: 'default title',
    message: 'default message',
    enabled: false,
  });

  const [teamsUpdated, setTeamsUpdated] = useState(false);

  const [categoriesDropDownList, setCategoriesDropDownList] = useState([]);

  const [sectionDetails, setSectionDetails] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [toastModalState, setToastModalState] = useState({
    title: '',
    description: '',
    status: '',
    enabled: false,
  });

  const showMessageDialog = (
    title = 'default title',
    message = 'default message',
  ) => {
    setMessageDialogState({ enabled: true, title, message });
  };

  const hideMessageDialog = () => {
    setMessageDialogState({ enabled: false, title: '', message: '' });
  };

  const showLoadingSpinner = (enabled = false) => {
    setLoadingSpinnerEnabled(enabled);
  };

  const showToastModal = (title = '', description = '', status = '') => {
    setToastModalState({ enabled: true, title, description, status });
  };

  const hideToastModal = () => {
    setToastModalState({
      enabled: false,
      title: '',
      description: '',
      status: '',
    });
  };

  /**
   * when status is false, token is optional. Localstorage is cleared
   * and isLoggedIn = N is set in the local storage
   *
   * when status is true, token may or may not be passed. If token is
   * passed jwtToen = token is set in the local storage
   *
   * also, setLoggedIn(status) is called to set the loggedIn flag
   * @param {*} status
   * @param {*} token
   */

  const contextObject = {
    formConfig,
    setFormConfig,

    userDoc,
    setUserDoc,
    teamsUpdated,
    setTeamsUpdated,

    messageDialogState,
    showMessageDialog,
    hideMessageDialog,

    loadingSpinnerEnabled,
    showLoadingSpinner,

    categoriesDropDownList,
    setCategoriesDropDownList,

    loggedIn,
    setLoggedIn,

    sectionDetails,
    setSectionDetails,
    isDarkMode,
    setIsDarkMode,
    isDrawerOpen,
    setIsDrawerOpen,

    toastModalState,
    showToastModal,
    hideToastModal,
  };

  return (
    <Contexts.Provider value={contextObject}>{children}</Contexts.Provider>
  );
};

export default AppStorage;
