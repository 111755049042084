import { InputBase, makeStyles, withStyles } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import themeDark from '../../../../UI/Themes/ThemeDark';

export const SV_PLAY_LOGO =
  'https://d25s2jqw4qdf1e.cloudfront.net/f6d8fb16-2aab-4057-b190-88c8afb7ecb8.png';

export const useStyles = makeStyles(() => ({
  placeholder: {
    color: '#000000',
    fontFamily: 'SF Pro Display !important',
    fontWeight: 600,
    opacity: 1,
  },
  input: {
    borderRadius: '10px',
    border: '1px solid #000',
  },
}));

export const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #000',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: ['SF Pro Display'],
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    fontWeight: 600,
    color: '#000',
  },
}))(InputBase);

export const customTheme = createMuiTheme({
  ...themeDark,
  palette: {
    ...themeDark.palette,
    background: {
      ...themeDark.palette.background,
      default: '#fff',
    },
  },
});
