import React from 'react';
import { Dialog, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './Styles';

const UploadingDialog = ({ open, text }) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <div className={classes.uploadingDialog}>
        <Typography variant="overline">{text}</Typography>
        <CircularProgress style={{ color: '#fff' }} />
      </div>
    </Dialog>
  );
};

UploadingDialog.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,
};

UploadingDialog.defaultProps = {
  open: false,
  text: 'Uploading',
};

export default UploadingDialog;
