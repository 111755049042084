import Cookies from 'js-cookie';
import {
  COOKIES_ROOT_DOMAIN,
  COOKIE_REFRESH_ID,
  COOKIE_REFRESH_TOKEN,
} from '../../constants';

// ---------- cookie helpers ------------

const setCookie = (key, value) => {
  Cookies.set(key, value, {
    expires: 730,
    domain: COOKIES_ROOT_DOMAIN,
    path: '/',
  });
};

const resetCookies = () => {
  setCookie(COOKIE_REFRESH_TOKEN, '');
  setCookie(COOKIE_REFRESH_ID, '');
};

// ---------- auth service methods ------------

export const getAuthData = () => {
  const authToken = localStorage.getItem('authToken');
  const refreshToken = Cookies.get(COOKIE_REFRESH_TOKEN);
  const refreshId = Cookies.get(COOKIE_REFRESH_ID);
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  return {
    authToken,
    refreshToken,
    refreshId,
    isLoggedIn: isLoggedIn && isLoggedIn === 'Y',
  };
};

export const resetAuthData = () => {
  localStorage.clear();
  resetCookies();
  localStorage.setItem('isLoggedIn', 'N');
};

export const setAuthData = ({ authToken, refreshToken, refreshId }) => {
  localStorage.setItem('authToken', authToken);

  // set same in cookies
  setCookie(COOKIE_REFRESH_TOKEN, refreshToken);
  setCookie(COOKIE_REFRESH_ID, refreshId);
};

export const setLoggedInFlag = booleanValue => {
  if (!booleanValue) {
    resetCookies();
  }
  localStorage.setItem('isLoggedIn', booleanValue ? 'Y' : 'N');
};

export const updateAuthTokenOnly = authToken => {
  localStorage.setItem('authToken', authToken);
};

export const getAuthorizationHeaderValue = () => {
  const authToken = localStorage.getItem('authToken');
  return authToken ? `Bearer ${authToken}` : '';
};
