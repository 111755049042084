import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { color } from '../color/colorConfig';

const breakpoints = createBreakpoints({});

const themeDark = createMuiTheme({
  palette: {
    background: {
      default: '#000000',
      paper: '#090d12',
    },
    primary: {
      main: '#090d12',
    },
    type: 'dark',
  },
  typography: {
    fontFamily: 'gotham',
    h1: {
      fontFamily: 'gotham',
      fontSize: '5rem',
      [breakpoints.down('xs')]: {
        fontFamily: '2rem',
      },
    },
    h2: {
      fontFamily: 'gotham',
      fontSize: '4.2rem',
      [breakpoints.down('xs')]: {
        fontFamily: '1.68rem',
      },
    },
    h3: {
      fontFamily: 'gotham',
      fontSize: '3.57rem',
      [breakpoints.down('xs')]: {
        fontFamily: '1.5rem',
      },
    },
    h4: {
      fontFamily: 'gotham',
      fontSize: '1.875rem',
      fontWeight: 600,

      [breakpoints.down('xs')]: {
        fontSize: '1.144rem',
      },
    },
    h5: {
      fontFamily: 'gotham',
      fontSize: '1.75rem',
      [breakpoints.down('xs')]: {
        fontFamily: '0.704rem',
      },
    },
    h6: {
      fontFamily: 'gotham',
      fontSize: '1.571rem',
      [breakpoints.down('xs')]: {
        fontFamily: '0.6284rem',
      },
    },
    // for styling paragraphs
    body2: {
      fontFamily: 'gothamBook',
      fontSize: '0.813rem',
      [breakpoints.down('xs')]: {
        fontSize: '0.514rem',
      },
    },
  },
});

const commonStyles = {
  scroll: {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#22222d',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
};

themeDark.overrides = {
  // Textfield Outlined style

  MuiOutlinedInput: {
    root: {
      '&$focused $notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.7)',
      },
      '&$hover $notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.7)',
      },
    },
    input: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #000 inset',
        '-webkit-text-fill-color': '#fff',
      },
    },
  },
  // Textfield label style
  MuiFormLabel: {
    root: {
      '&$focused': {
        color: 'rgba(255, 255, 255, 0.7)',
      },
    },
  },
  // Datepicker Style
  MuiPickersDay: {
    daySelected: {
      backgroundColor: color.blue.main_color,
      '&:hover': {
        backgroundColor: color.blue.hover_color,
      },
    },
    current: {
      color: color.blue.main_color,
    },
  },
  // Timeclock pin style
  MuiPickersClock: {
    pin: {
      backgroundColor: color.blue.main_color,
    },
  },
  // Timeclock pointer style
  MuiPickersClockPointer: {
    pointer: {
      backgroundColor: color.blue.main_color,
    },
    thumb: {
      border: `14px solid ${color.blue.main_color}`,
    },
  },
  // Datepicker / Timepicker button style
  MuiButton: {
    textPrimary: {
      color: color.blue.main_color,
      '&:hover': {
        color: color.blue.hover_color,
      },
    },
  },
  // Autocomplete Dropdown scrollbar style
  MuiAutocomplete: {
    listbox: commonStyles.scroll,
  },
  MuiMenu: {
    paper: commonStyles.scroll,
  },

  MuiSlider: {
    rail: { backgroundColor: color.white.main_color },
    track: { backgroundColor: color.white.main_color },
    thumb: {
      backgroundColor: color.blue.main_color,
    },
  },
  // dialog title
  MuiDialogTitle: {
    root: {
      backgroundColor: color.dialogHeading,
      fontSize: '3vh',
      padding: '7px 15px',
      textTransform: 'capitalize',
    },
  },
  MuiDialogContent: {
    root: {
      paddingTop: '3.2vh',
      ...commonStyles.scroll,
    },
  },
  MuiDialogActions: {
    root: {
      paddingBottom: '2.6vh',
      paddingRight: '1.8vw',
      paddingLeft: '1.8vw',
    },
  },
};

export default themeDark;
