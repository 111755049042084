import React from 'react';

// a default context object to help indicate to
// to intellisense the values present in the context.
// this helps for autocomplete
const defaultValuesToHelpIntelliSense = {
  isLoggedIn: false,

  setAuthCredentials: ({ authToken, refreshToken, refreshId }) =>
    console.log('onAuthCredentialsReceived'),
  setAsLoggedIn: () => console.log('setLoggedIn'),

  logout: () => console.log('logout'),
};

const AuthContext = React.createContext(defaultValuesToHelpIntelliSense);

export default AuthContext;
