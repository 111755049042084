import React from 'react';
import styles from './styles.module.css';
import Loader from '../../uiComponents/Loader/Loader';

function SVPlayRootLoader() {
  return (
    <div className={styles.sv_play_container}>
      <Loader color="#3297DB" />
    </div>
  );
}

export default SVPlayRootLoader;
