import React, { useContext, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import Root from './pages/Root/Root';
import Contexts from './contexts/Contexts';
import UploadingDialog from './Components/UploadingDialog';
import themeDark from './UI/Themes/ThemeDark';
import useQuery from './customHooks/useQuery';
import { customTheme } from './pages/Root/Basic/SVPlay/utils';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const { loadingSpinnerEnabled } = useContext(Contexts);

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
        },
      }),
    [prefersDarkMode],
  );

  const query = useQuery();
  const mode = query.get('mode');

  return (
    <ThemeProvider theme={mode === 'SV_PLAY' ? customTheme : themeDark}>
      <CssBaseline />
      <Router>
        <Root mode={mode} />
      </Router>
      <UploadingDialog text="" open={loadingSpinnerEnabled} />
    </ThemeProvider>
  );
}

export default App;
