import Axios from 'axios';
import { BASE_URL } from '../../constants';

const getNewAccessToken = async ({ refreshToken = '', refreshId = '' }) => {
  const response = await Axios.post(`${BASE_URL}/api/refresh`, {
    refresh_token: refreshToken,
    refresh_id: refreshId,
  });

  const { data } = response.data;
  const { access } = data;

  return access;
};

export default getNewAccessToken;
