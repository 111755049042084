export const generateRedirectUrl = ({
  initialUrl,
  authToken,
  refreshToken,
  refreshId,
}) => {
  const startingChar = initialUrl.includes('?') ? '&' : '?';

  const encodedAuthToken = encodeURIComponent(authToken);
  const encodedRefreshToken = encodeURIComponent(refreshToken);
  const encodedRefreshId = encodeURIComponent(refreshId);

  return `${initialUrl}${startingChar}auth_token=${encodedAuthToken}&refresh_token=${encodedRefreshToken}&refresh_id=${encodedRefreshId}`;
};

export default null;
