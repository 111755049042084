export const color = {
  blue: {
    main_color: 'rgb(0, 130, 213)',
    hover_color: '#006aad',
  },
  red: {
    main_color: 'rgb(255, 0, 6)',
    hover_color: 'rgb(240, 0, 6,0.8)',
  },
  green: {
    main_color: '#07E65A',
    hover_color: 'rgb(7, 195, 90,0.8)',
  },
  white: {
    main_color: '#ffffff',
    hover_color: '#bababa',
  },
  black: {
    main_color: '#000',
    hover_color: '#333333',
  },
  dialogHeading: 'rgb(0, 130, 213,0.3)',
};

export default null;
